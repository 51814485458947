.input-group > input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group.input-filters {
  width: 170px;
  margin-right: 10px;
  z-index: 5;
}

button.export-excel {
  margin-left: 5px;
}
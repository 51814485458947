.bottom-message {
  position: absolute;
  left: 0;
  bottom: 10px;
  font-size: 8px;
}

.background-container {
  position: absolute;
  width: 100%;
  height: calc(100% - 56px);
  /* background-image: url("/public/background.jpeg");
  background-position: center;
  background-size: cover; */
  overflow: hidden;
  margin-right: 15px;
}

.wizard-container [data-background-color="blue"] {
  background-color: rgba(0, 0, 0, .03);
}

.wizard-container [data-background-color="blue"] .description {
  color: #aaa;
}

.wizard-container .wizard-navigation a.nav-link {
  font-weight: 500 !important;
}

.wizard-container .wizard-navigation a.nav-link.active {
  color: rgba(0, 0, 0, .03) !important;
}

.wizard-container .wizard-navigation .nav-item {
  cursor: pointer;
}

.wizard-container .wizard-navigation .moving-tab {
  color: rgb(var(--bs-primary-rgb));
}
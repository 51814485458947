form label {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}

.styled-date-picker-container .popover-inner > .popover-header {
  padding: 8px;
}

#site-logo {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 15vw;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

/* MOBILE */
@media screen and (max-width: 768px) {
  #site-logo {
    display: none;
    /* bottom: 10px;
    width: 50vw;
    right: calc(50% - 25vw); */
  }

  div.navbar-collapse > ul.navbar-nav div.justify-content-end {
    flex-direction: column;
  }

  div.navbar-collapse > ul.navbar-nav div.justify-content-end > li.nav-item  {
    text-align: end;
  }
}
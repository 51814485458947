.button-container-left>button {
  margin-right: 10px;
}
.button-container-right>button {
  margin-left: 10px;
}

.subscription-container {
  position: relative;
}

.subscription-remove-button> button {
  position: relative;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .subscription-remove-button > button {
    position: absolute;
    right: -2rem;
    bottom: 2rem;
    width: 2.6rem;
  }
}

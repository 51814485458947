.input-group > input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.badge > h6 {
  margin-bottom: 0;
}